import styled from 'styled-components';
import { colors, breakPoints } from '@/styles';

export const CopyTextControl = styled.div<{ $copied?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.$copied ? colors.semantic.positive.primary : 'inherit')};
  cursor: pointer;
`;
