import { Row } from '@/ui/primatives';
import * as Styled from './styled';
import Button from '@/ui/Button';

interface BillingCycleSwitchProps {
  mode: 'monthly' | 'annual';
  onToggle: (mode: 'monthly' | 'annual') => void;
}

const BillingCycleSwitch: React.FC<BillingCycleSwitchProps & React.HTMLAttributes<HTMLDivElement>> = ({
  mode = 'monthly',
  onToggle,
  ...rest
}) => {
  return (
    <Styled.BillingCycleSwitch $borderWidth={2} {...rest}>
      <Row alignItems="center" gap={10}>
        {mode === 'monthly' ? (
          <Button.Black nowrap onClick={() => onToggle('monthly')} rounded style={{ width: '100%' }}>
            Pay Monthly
          </Button.Black>
        ) : (
          <Button.Text nowrap onClick={() => onToggle('monthly')} rounded style={{ width: '100%' }}>
            Pay Monthly
          </Button.Text>
        )}

        {mode === 'annual' ? (
          <Button.Black nowrap onClick={() => onToggle('annual')} rounded style={{ width: '100%' }}>
            Pay Yearly (Save 20%)
          </Button.Black>
        ) : (
          <Button.Text nowrap onClick={() => onToggle('annual')} rounded style={{ width: '100%' }}>
            Pay Yearly (Save 20%)
          </Button.Text>
        )}
      </Row>
    </Styled.BillingCycleSwitch>
  );
};

export default BillingCycleSwitch;
