'use client';
import styled from 'styled-components';
import { colors } from '@/styles';

export const CompanyList = styled.div<{ $nowrap?: boolean }>`
  display: flex;
  flex-wrap: ${(props) => (props.$nowrap ? 'nowrap' : 'wrap')};
  max-width: 100%;
  overflow-x: auto;
  flex-direction: row;
  column-gap: 40px;
  row-gap: 20px;
  justify-content: ${(props) => (props.$nowrap ? 'flex-start' : 'center')};
  padding-bottom: ${(props) => (props.$nowrap ? '20px' : '0')};
  /* Hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const CompanyImage = styled.div`
  display: flex;
  flex: 0 0 auto;
  img {
    max-width: 100px;
    max-height: 40px;
    object-fit: contain;
  }
`;
