'use client';
import { Identity } from '@/hooks/useIdentity';
import useReferralCode from '@/hooks/useReferralCode';

import { FormValues } from './CompleteAccountForm';
import CompleteAccountForm from './CompleteAccountForm';
import { IProviderUsernameResponse } from '@/lib/usersApi';
import { IUser } from '@/types';
interface CompleteAccountProps {
  redirect?: string;
  email: string;
  providerUsernameResponse?: IProviderUsernameResponse;
}

const CompleteAccount: React.FC<CompleteAccountProps> = ({ redirect = '/', email, providerUsernameResponse }) => {
  const { user, completeAccount, error } = Identity.useContainer();
  const { referralCode } = useReferralCode();

  const navigateToRedirectUrl = () => {
    window.location.href = redirect;
  };

  const submitLogin = async ({ name, username, password }: FormValues) => {
    completeAccount({
      email,
      name,
      username,
      password,
      referralCode,
      onSuccess: navigateToRedirectUrl,
    });
  };

  return (
    <>
      <CompleteAccountForm
        user={user as IUser}
        error={error}
        onSubmit={submitLogin}
        providerUsernameResponse={providerUsernameResponse}
      />
    </>
  );
};

export default CompleteAccount;
