'use client';
import React, { useState } from 'react';
import * as Icon from '@/ui/Icon';
import * as Styled from './styled';
import useClipboard from '@/hooks/useClipboard';
import { Simple as Tooltip } from '@/ui/Tooltip';
type TTrackingEvent = { name: string; payload: Object };

type CopyTextControlProps = React.HTMLAttributes<HTMLDivElement> & {
  id: string;
  assetType?: string;
  value: string;
  event?: TTrackingEvent;
  children?: React.ReactNode;
};

const CopyTextControl: React.FC<CopyTextControlProps> = ({
  id,
  assetType = 'path',
  value,
  event,
  children,
  ...props
}) => {
  const elementId = `copy-${id}`;
  const [copied, copy] = useClipboard(value, { event });

  const [isOpen, setIsOpen] = useState(false);

  const tooltopContent = copied ? 'Copied!' : `Copy ${assetType}`;

  return (
    <Tooltip content={tooltopContent} open={!!copied || isOpen} small>
      <Styled.CopyTextControl
        data-tooltip-id={`${elementId}`}
        $copied={!!copied}
        onClick={copy}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        {...props}
      >
        {children || <Icon.Copy />}
      </Styled.CopyTextControl>
    </Tooltip>
  );
};

export default CopyTextControl;
